<template>
  <b-form-group :label="label" :class="formGroupClass">
    <b-input-group :prepend="prepend" :append="append">
      <b-form-input
        :id="id"
        v-model="model"
        :input="inputValue()"
        :state="state != undefined ? state : null"
        :class="getClass"
        @keypress="isNumber($event)"
        @keyup.delete="$emit('deletePressed')"
        :placeholder="placeholder ? placeholder : label"
        @keyup.enter="onEnter"
        :readonly="readonly"
        :maxlength="maxLength"
      ></b-form-input>
    </b-input-group>
    <b-form-invalid-feedback
      :id="`${id}-input-live-feedback`"
      v-if="errorMessage || (vError && vError.$error === true)"
    >
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-if="vError && vError.$error === true">
        <span v-if="vError.required === false">
          Bu alanı doldurmanız gerekmektedir.
        </span>
        <span v-if="vError.email === false">
          Bu alana geçerli bir eposta adresi girmeniz gerekmektedir.
        </span>
      </template>
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    state: { type: Boolean, default: undefined },
    stateBorder: { type: Boolean, default: undefined },
    placeholder: String,
    defaultValue: { type: String | Number, default: '' },
    readonly: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    vError: { type: Object, default: null },
    maxLength: { type: String, default: '9999' },
    prepend: { type: String, default: '' },
    append: { type: String, default: '' },
    formGroupClass: { type: String, default: '' },
    // min: {
    //   type: Number | String,
    //   default: -1,
    // },
    // max: {
    //   type: Number | String,
    //   default: -1,
    // },
  },
  name: 'NumberInput',
  data() {
    return {
      model: '',
    };
  },
  created() {
    this.setDefaultValue();
  },
  computed: {
    getClass() {
      let _class = '';

      if (this.stateBorder != undefined)
        _class = this.stateBorder === true ? 'border-success' : 'border-danger';

      return _class;
    },
  },
  methods: {
    setDefaultValue() {
      this.model = this.defaultValue !== '' ? this.defaultValue : '';
    },
    inputValue() {
      this.$emit('input', this.model);
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        if (this.model)
          if (this.maxLength <= this.model.length) {
            this.$emit('maxLengthExceeded');
          }
        return true;
      }
    },
    controlMinMax(evt) {
      if (this.max != -1 && parseFloat(this.value) >= this.max)
        this.value = this.max;

      if (this.min != -1 && parseFloat(this.value) < this.min)
        this.value = this.min;
    },
    inputValue() {
      this.$emit('input', this.model);
    },
    changeInput() {
      if (this.state != null) {
        this.$emit('formReset', true);
        setTimeout(() => {
          this.$emit('formReset', false);
        }, 200);
      }
    },
    onEnter() {
      this.$emit('click-enter');
    },
  },
  watch: {
    defaultValue() {
      this.setDefaultValue();
    },
    value(newVal) {
      this.model = newVal;
    },
  },
};
</script>
